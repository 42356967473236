console.log('Ready javascript');
// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);

$('.gallery-item a').attr('data-fancybox', 'gallery');
$('.gallery-item a').addClass('dim');


//Component c-menu
var tempOffset = 0;
document.querySelector(".hamburguer").addEventListener("click", function()
{
  document.querySelector(".full-menu").classList.toggle("active");
  document.querySelector(".hamburguer").classList.toggle("close-hamburguer");
 
  if ($(".full-menu").hasClass("active")) {

    tempOffset = window.pageYOffset;
    // console.log(tempOffset  + ' is-active');

    document.querySelector(".body").classList.add("overflow-hidden");
    document.querySelector(".body").classList.add("js-is-active-menu");


    setTimeout(() => {
      document.querySelector(".body").classList.remove("overflow-hidden");
    }, 400);
  } else {
    document.querySelector(".body").classList.remove("overflow-hidden");
    document.querySelector(".body").classList.remove("js-is-active-menu");

    // console.log(tempOffset  + ' no-active');
    window.scrollBy(0,tempOffset);
    
  }
 
});
document.getElementById("c-menu__btn").addEventListener("click", function(){
  document.getElementsByClassName("hamburguer")[0].click();
});


$(document).keyup(function(e) {
  if ($(".full-menu").hasClass("active")) {
    if (e.keyCode === 27) document.getElementsByClassName("hamburguer")[0].click();   // esc
  }
});




$(".js-c-social-post__button").click(function(){ 
  $(".c-social-post").toggleClass("aos-init aos-animate");
});



























